import React, { useContext, useState, useEffect } from "react";
import { Drawer, Box, Grid, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Form from "../../Components/Form/Form";
import TextFieldRenderer from "../../Components/Form/TextFieldRenderer";
import {
  formValidator,
  isEmpty,
  isInvalidEmail,
  isInvalidPhoneNumber,
} from "../../Components/Form/validators";
import { css } from "@emotion/css";
import SelectRenderer from "../../Components/Form/SelectRenderer";
import { GET_CLUBS_SA, REGISTER_USER_SA } from "./SuperAdminService";
import { REGISTER_CLUB_USER } from "../../graphql/clubGraphql";
import { GET_CLUB_TEAMS } from "../../graphql/postGraphql";
import { AuthContext } from "../../context/AuthContext";
import { SnackBarContext } from "../../context/SnackBarContext";
import Autocomplete from "../../Components/Autocomplete/Autocomplete";
import { useTranslation } from "../../context/Internationalization";
import DrawerFormTitle from "../../Components/Common/DrawerFormTitle/DrawerFormTitle";
import useGraphql from "../../Hooks/useGraphql";
import useLog from "../../Hooks/useLog";
import { isSuperAdmin } from "../../utility/Utils";
import { getStorage } from "../../auth/utils";
import { USER_ROLES } from "../../utility/Enum";

const DrawerFormStyles = getStorage().getItem("impersonate")
  ? css`
      .wrapper {
        width: 100%;
        max-width: 400px;
        margin-top: 24px;
        padding: 10px 20px;
      }
    `
  : css`
      .wrapper {
        width: 100%;
        max-width: 400px;
        padding: 10px 20px;
      }
    `;

const schema = (selectedRole, t, isSuperAdmin, teams) => [
  {
    label: t("firstName"),
    name: "firstName",
    validators: [
      {
        validator: isEmpty,
        text: (label) => `${label} ${t("cannot_be_empty")}`,
      },
    ],
    renderer: TextFieldRenderer,
    row: 1,
  },
  {
    label: t("lastName"),
    name: "lastName",
    renderer: TextFieldRenderer,
    row: 2,
  },
  {
    label: t("mobile"),
    name: "mobile",
    validators: [
      {
        validator: isInvalidPhoneNumber,
        text: (_label) => `${t("error_invalid_phone_number")}`,
      },
    ],
    renderer: TextFieldRenderer,
    row: 3,
  },
  {
    label: t("email"),
    name: "email",
    validators: [
      {
        validator: isInvalidEmail,
        text: (_label) => `${t("error_invalid_email")}`,
      },
    ],
    renderer: TextFieldRenderer,
    row: 4,
  },
  {
    label: t("role"),
    name: "role",
    options: [
      { text: t("coach"), value: USER_ROLES.COACH },
      { text: t("team_admin"), value: USER_ROLES.TEAM_ADMIN },
      { text: t("club_admin"), value: USER_ROLES.CLUB_ADMIN },
    ],
    renderer: SelectRenderer,
    row: 5,
  },
  !isSuperAdmin &&
    (selectedRole === USER_ROLES.COACH ||
      selectedRole === USER_ROLES.TEAM_ADMIN) && {
      label: t("selectTeam"),
      name: "teamId",
      options: teams.map((team) => {
        return { text: team.name, value: team._id };
      }),
      renderer: SelectRenderer,
      row: 6,
    },
  (isSuperAdmin || selectedRole === USER_ROLES.CLUB_ADMIN) && {
    label: t("club_page_title"),
    name: "clubId",
    renderer: (props) => (
      <Autocomplete
        {...props}
        key={"clubId"}
        placeholder={"Search Club"}
        query={GET_CLUBS_SA}
        showSearchListResults
        onChangeName={"clubId"}
        mapResults={(results) => {
          return results?.getClubsSA?.map((club) => {
            return { text: club.name, value: club._id };
          });
        }}
      />
    ),
    row: 6,
  },
  (isSuperAdmin ||
    selectedRole === USER_ROLES.COACH ||
    selectedRole === USER_ROLES.TEAM_ADMIN) && {
    label: "Team ID",
    name: "teamId",
    renderer: TextFieldRenderer,
    row: 7,
  },
];

const initialState = {
  firstName: "",
  lastName: "",
  mobile: "",
  role: "",
  clubId: "",
  teamId: "",
};

const RegisterUserDrawer = ({ openDrawer, onCloseDrawer }) => {
  const { authState } = useContext(AuthContext);
  const [values, setValues] = useState(initialState);
  const snackBar = useContext(SnackBarContext);
  const [teams, setTeams] = useState([]);
  const { t } = useTranslation();
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();
  const _schema = schema(values.role, t, isSuperAdmin(authState.user), teams);

  useEffect(() => {
    if (!isSuperAdmin(authState.user) && authState.user.club?._id) {
      axiosGraphQL({
        query: GET_CLUB_TEAMS,
        variables: {
          clubId: authState.user.club?._id,
          teamsSkip: 0,
          teamsLimit: 500,
        },
      })
        .then((response) => {
          const { ClubTeams } = response.data.data;
          setTeams(ClubTeams);
        })
        .catch((error) => {
          errorLog(error, "RegisterUserDrawer", "useEffect", "GET_CLUB_TEAMS");
          snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
    }
  }, [authState.user.club?._id]);

  useEffect(() => {
    if (values.role === USER_ROLES.CLUB_ADMIN) {
      setValues({ ...values, teamId: "" });
    } else {
      setValues({ ...values, clubId: "" });
    }
  }, [values.role]);

  const getRolesTranslated = (role, isError) => {
    switch (role) {
      case USER_ROLES.CLUB_ADMIN:
        return isError ? t("error_creating_club_admin") : t("the_club_admin");
      case USER_ROLES.TEAM_ADMIN:
        return isError ? t("error_creating_team_admin") : t("the_team_admin");
      case USER_ROLES.COACH:
        return isError ? t("error_creating_coach") : t("the_coach");
    }
  };

  const handleSuccess = (message) => {
    snackBar.setOptions({
      alertMessage: message,
      alertSeverity: "success",
    });
    snackBar.setSnackbarOpen(true);
    setValues(initialState);
  };

  const handleError = (error, queryName) => {
    errorLog(error, "RegisterUserDrawer", "handleSubmit", queryName);
    snackBar.setOptions({
      alertMessage: error.message,
      alertSeverity: "error",
    });
    snackBar.setSnackbarOpen(true);
  };

  const handleSubmit = () => {
    const errors = formValidator({ schema: _schema, values });
    if (Object.keys(errors).length > 0) {
      setValues((prevState) => ({ ...prevState, errors }));
    } else {
      const registerUser = (query, queryName) => {
        axiosGraphQL({ query, variables: values })
          .then(async () => {
            handleSuccess(
              `${getRolesTranslated(values.role)} ${t("created_successfully")}`
            );
          })
          .catch((error) => {
            handleError(error, queryName);
          });
      };

      if (isSuperAdmin(authState.user)) {
        registerUser(REGISTER_USER_SA, "REGISTER_USER_SA");
      } else {
        if (values.role === USER_ROLES.CLUB_ADMIN) {
          values.clubId = authState.user.club?._id;
        }
        registerUser(REGISTER_CLUB_USER, "REGISTER_CLUB_USER");
      }
    }
  };

  const onChange = ({ target: { name, value } }) => {
    if (name === "mobile") {
      setValues((prevState) => ({
        ...prevState,
        [name]: value?.replace(/ /g, ""),
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleCloseDrawer = () => {
    setValues(initialState);
    onCloseDrawer();
  };

  return (
    <Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer}>
      <Box className={DrawerFormStyles}>
        <Box className="wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton onClick={handleCloseDrawer}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ maxWidth: "400px" }}>
            <DrawerFormTitle
              actionText={t("register")}
              className="grid-container-header"
              text={t("create_user")}
            />
            <Grid item xs={12}>
              <Form
                schema={_schema}
                values={values}
                onChange={onChange}
                errors={values.errors}
              />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 24 }}>
              <Button onClick={handleSubmit} fullWidth>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default RegisterUserDrawer;
