import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import { browserTracingIntegration, replayIntegration } from "@sentry/react";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { playerDexTheme } from "./theme/theme";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

// Throttle error reporting to avoid spam
let lastSentryErrorTime = 0;
const THROTTLE_TIME = 60000; // Prevent duplicate events every 1 min

Sentry.init({
  dsn: SENTRY_DSN,

  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysOnErrorSampleRate: 0.5, // Capture 50% of errors on replay
  replaysSessionSampleRate: 0.1, // Capture 10% of sessions

  tracesSampleRate: 0.1, // Capture 10% of performance traces
  maxValueLength: 2000, // Allow slightly larger error messages

  beforeSend(event) {
    const now = Date.now();
    if (now - lastSentryErrorTime < THROTTLE_TIME) return null; // Prevent spam
    lastSentryErrorTime = now;

    const ignoredErrors = [
      "ResizeObserver",
      "NonCriticalError",
      "NetworkError",
    ];
    if (
      event.exception &&
      ignoredErrors.some((err) =>
        event.exception.values?.[0]?.value.includes(err)
      )
    ) {
      return null;
    }

    // Filter out unwanted logs
    if (event.level === "info") return null;

    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={playerDexTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
