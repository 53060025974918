import axios from "axios";
import "firebase/auth";
import { appVersion, getFirebaseUser, getStorage } from "../auth/utils";
import { DateTime } from "luxon";
import { chatUtil } from "../utility/ChatUtils";

axios.interceptors.request.use(
  async (config) => {
    if (config?.url?.indexOf("securetoken.googleapis.com") > -1) {
      return config;
    }
    const token = getStorage().getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const impersonate = getStorage().getItem("impersonate");
    if (impersonate && JSON.parse(impersonate)) {
      config.headers.impersonate = JSON.parse(impersonate).mobile;
    }

    // 🔹 Add X-App-Version Header
    config.headers["X-App-Version"] = appVersion;

    const tokenLastValidity = getStorage().getItem("tokenLastValidity");

    if (
      tokenLastValidity &&
      DateTime.fromMillis(+tokenLastValidity).plus({ minutes: 30 }).toMillis() <
        DateTime.now().toMillis()
    ) {
      const user = await getFirebaseUser();
      if (user && Object.keys(user).length > 0) {
        const token = await user.getIdToken(true);
        chatUtil.updateConnection(token);
        getStorage().setItem("tokenLastValidity", Date.now().toString());
        getStorage().setItem("token", token);
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    console.log("interceptor error", error);
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    const originalRequest = error?.config;

    if (error?.response?.status === 401) {
      getStorage().removeItem("token");
      getStorage().removeItem("refreshToken");
      window.location.href = "/login";
      return Promise.reject(error);
    }

    return Promise.reject(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  }
);

const baseUrl = process.env.REACT_APP_BASE_URL;

export function axiosGraphQL(payload) {
  return axios.post(`${baseUrl}/graphql`, payload);
}

export function axiosGet(url) {
  return axios.get(`${baseUrl}${url}`);
}

export function axiosPost(url, payload) {
  return axios.post(`${baseUrl}${url}`, payload);
}

export function sendMessage(data) {
  return axios.post(`${baseUrl}/chat/message`, data);
}
