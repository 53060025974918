import React, { useContext, useEffect, useState, Suspense } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { useAuthContext } from "./context/AuthContext";
import playerDexLogo from "./assets/images/admin-icon-2/Content.png";
import playerDex from "./assets/images/admin-icon-2/playerdex.png";
import HomeIcon from "./assets/images/icons/home.png";
import HomeSelectedIcon from "./assets/images/icons/home_selected.png";
import ScheduleIcon from "./assets/images/icons/schedule.png";
import ScheduleSelectedIcon from "./assets/images/icons/schedule_selected.png";
import ClubIcon from "./assets/images/icons/club.png";
import ClubSelectedIcon from "./assets/images/icons/club_selected.png";
import SearchIcon from "./assets/images/admin-icon-2/search-icon.png";
import SearchSelectedIcon from "./assets/images/admin-icon-2/search.png";
import LeagueIcon from "./assets/images/admin-icon/Menu-Icons-2.png";
import LeagueSelectedIcon from "./assets/images/admin-icon/Menu-Icons-3.png";
import MessagesIcon from "./assets/images/icons/messages.png";
import MessagesSelectedIcon from "./assets/images/icons/messages_selected.png";
import ShopIcon from "./assets/images/icons/shopIcon.svg";
import ShopSelectedIcon from "./assets/images/icons/shopSelectedIcon.svg";
import notificationIcon from "./assets/images/icons/notificationIcon.svg";
import notificationSelectedIcon from "./assets/images/icons/notificationSelectedIcon.svg";
import formsIcon from "./assets/images/icons/formsIcon.svg";
import formsSelectedIcon from "./assets/images/icons/formsSelectedIcon.svg";
import registerUserIcon from "./assets/images/admin-icon/Menu-Icons-6.png";
import registerUserSelectedIcon from "./assets/images/admin-icon/Menu-Icons-7.png";
import filesIcon from "./assets/images/admin-icon/Menu-Icons-8.png";
import filesSelectedIcon from "./assets/images/admin-icon/Menu-Icons-9.png";
import rolesIcon from "./assets/images/admin-icon/Menu-Icons-4.png";
import rolesSelectedIcon from "./assets/images/admin-icon/Menu-Icons-5.png";

import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { colors } from "./theme/colors";
import { fonts } from "./theme/fonts";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Search } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { GET_PROFILES } from "./pages/Membership/MembershipService";
import { Avatar, Chip, MenuItem } from "@mui/material";
import LogoutIcon from "./assets/images/Svg/LogoutIcon";
import {
  getProfilesTeamDetails,
  isClubAdmin,
  isSuperAdmin,
} from "./utility/Utils";
import { useTranslation } from "./context/Internationalization";

import Loader from "./Components/Loader";
import TranslationToggle from "./Components/TranslationToggle";
import TopBar from "./Components/TopBar/TopBar";
import { GET_USER } from "./graphql/userGraphql";
import { clearCookies, clearStorages, getStorage, signOut } from "./auth/utils";
import { useInvitations } from "./context/InvitationContext";
import { GET_CLUB_TEAMS } from "./graphql/postGraphql";
import LogoutConfirmationModal from "./Components/LogoutConfirmationModal";
import ClubChooseConfirmationModal from "./Components/ClubChooseConfirmationModal";
import { useMessages } from "./context/MessagesContext";
import { useAppState } from "./context/AppContext";
import { SnackBarContext } from "./context/SnackBarContext";
import useGraphql from "./Hooks/useGraphql";
import useLog from "./Hooks/useLog";
import RegisterUserDrawer from "./pages/SuperAdmin/RegisterUserDrawer";
import { localMap, USER_ROLES } from "./utility/Enum";

const { Grey, Base } = colors;

const {
  text: { md, sm },
} = fonts;

const drawerWidth = 312;

const MenuList = ({ menu, t, pathname, style, closeDrawerMobile }) => {
  return (
    <List style={style}>
      {menu(t).map((item, index) => {
        if (item.divider) {
          return (
            <div
              key={"divider" + index}
              style={{ borderTop: "1px solid #1F2831", margin: "16px" }}
            />
          );
        }

        const isActive = pathname.includes(item.path) || item.drawerOpened;

        return (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={item.onClick ? () => item.onClick() : undefined}
              style={{ paddingLeft: "24px", justifyContent: "space-between" }}
            >
              <div style={{ alignItems: "center", display: "flex" }}>
                <ListItemIcon style={{ minWidth: "38px" }}>
                  {item.icon(isActive)}
                </ListItemIcon>

                <NavLink
                  style={isActive ? styles.selectedLink : styles.link}
                  to={item.path}
                  onClick={closeDrawerMobile}
                >
                  {item.label}
                </NavLink>
              </div>
              {item.badge && (
                <Chip
                  style={{ height: 22 }}
                  label={item.badge}
                  color={"primary"}
                />
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

const AppShell = ({ window, children }) => {
  const [isRegisterUserDrawerOpen, setIsRegisterUserDrawerOpen] =
    useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [chooseClubModal, setChooseClubModal] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [players, setPlayers] = useState([]);
  const [search, setSearch] = useState("");
  const [unreadMessages, setUnreadMessages] = useState(0);
  const { t, switchLanguage, lang } = useTranslation();
  const auth = useAuthContext();
  const appState = useAppState();
  const {
    chatConnect,
    chatClient,
    rooms,
    getRooms,
    latestUpdate,
    getLatestMessage,
  } = useMessages();

  const { pathname } = useLocation();

  const club = auth.authState.user.club;
  const clubId = club?._id ?? 0;
  const user = auth.authState.user;
  const snackBar = useContext(SnackBarContext);
  const { invitations } = useInvitations();
  const { axiosGraphQL } = useGraphql();
  const { errorLog } = useLog();

  const impersonateProc = () => {
    const impersonate = getStorage().getItem("impersonate");
    if (impersonate) {
      getStorage().setItem("impersonate", JSON.stringify(user));
    } else {
      getStorage().setItem("user", JSON.stringify(user));
    }
  };

  const getClubTeams = () => {
    axiosGraphQL({
      query: GET_CLUB_TEAMS,
      variables: {
        clubId,
        teamsSkip: 0,
        teamsLimit: 500,
      },
    })
      .then((response) => {
        const clubTeams = response.data.data.ClubTeams;
        appState.setClubTeams(clubTeams);
        Intercom("boot", {
          app_id: "f5ktqeup",
          user_id: user?._id,
          phone: user?.mobile,
          avatar: {
            type: "avatar",
            image_url:
              user?.photo ||
              `${user?.firstName?.substring(0, 1)}${user?.lastName?.substring(
                0,
                1
              )}`,
          },
          company_id: clubId,
          company_name: user?.club?.name,
          company: {
            company_id: clubId,
            name: user?.club?.name,
            plan: user?.club?.isPremium ? "Premium" : "Basic",
            monthly_spend: user?.club?.monthlySpend,
            size: clubTeams?.length || 0,
            industry: clubTeams && clubTeams[0] && clubTeams[0].sport,
            company_website: user?.club?.website,
          },

          name: `${user?.firstName} ${user?.lastName}`, // Full name
          created_at: Date.now(), // Signup date as a Unix timestamp
        });

        Intercom("update");
        return response.data.data.ClubTeams;
      })
      .catch((error) => {
        errorLog(error, "AppShell", "getClubTeams", "GET_CLUB_TEAMS");
        snackBar.setOptions({
          alertMessage: error.message,
          alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  };

  const getUser = () => {
    axiosGraphQL({ query: GET_USER })
      .then(async (response) => {
        const user = response.data.data.me?.user;
        if (!user) {
          auth.logout();
          return;
        }

        const userLangDB = user.language;
        const userLangLS = getStorage().getItem("language");
        if (userLangDB !== userLangLS) {
          switchLanguage(userLangDB);
        }
        user.clubs = response.data.data.me?.clubs;
        if (user.clubs && user.clubs.length > 1) {
          user.club = user.clubs.find((club) => club?._id === clubId);
        } else {
          user.club = response.data.data.me?.club;
        }
        setSelectedClub(user.club);

        auth.setAuthState({ user, token: getStorage().getItem("token") });

        const isValidRole =
          user.roles.includes(USER_ROLES.CLUB_ADMIN) ||
          user.roles.includes(USER_ROLES.SUPER_ADMIN);
        if (!isValidRole) {
          auth.logout();
        }

        chatConnect();

        impersonateProc();
      })
      .catch((error) => {
        errorLog(error, "AppShell", "getUser", "GET_USER");
        snackBar.setOptions({
          alertMessage: error.message,
          alertSeverity: "error",
        });
        snackBar.setSnackbarOpen(true);
      });
  };

  useEffect(() => {
    if (!clubId) {
      snackBar.setOptions({
        alertMessage: t("your_account_does_not_belong_to_a_club"),
        alertSeverity: "error",
      });
      snackBar.setSnackbarOpen(true);
      setTimeout(() => {
        handleLogout();
      }, 4000);
    } else {
      getClubTeams();
      getUser();
    }
  }, []);

  useEffect(() => {
    if (chatClient) {
      getLatestMessage();
      getRooms();
    }
  }, [chatClient]);

  useEffect(() => {
    if (rooms && rooms.length > 0) {
      const filteredRooms = rooms.filter(
        (room) => room?.room?.metadata?.club === clubId
      );
      const roomsWithUnreadMessages = filteredRooms.filter(
        (room) => room.unreadMessages > 0
      );
      const totalUnread = roomsWithUnreadMessages.reduce(
        (sum, room) => sum + room.unreadMessages,
        0
      );
      setUnreadMessages(totalUnread);
    }
  }, [rooms, latestUpdate]);

  const isUserSuperAdmin = isSuperAdmin(user);
  const isUserClubAdmin = isClubAdmin(user);
  let menuLinks = (t) =>
    [
      {
        label: t("home"),
        path: "/news",
        icon: (isSelected) => (
          <img
            src={isSelected ? HomeSelectedIcon : HomeIcon}
            style={{ width: 24, height: 24 }}
            alt={"home-icon"}
          />
        ),
      },
      {
        label: t("schedule_title"),
        path: "/activities",
        icon: (isSelected) => (
          <img
            src={isSelected ? ScheduleSelectedIcon : ScheduleIcon}
            style={{ width: 24, height: 24 }}
            alt={"schedule-icon"}
          />
        ),
      },
      {
        label: t("club_page_title"),
        path: "/club/teams",
        icon: (isSelected) => (
          <img
            src={isSelected ? ClubSelectedIcon : ClubIcon}
            style={{ width: 24, height: 24 }}
            alt={"club-icon"}
          />
        ),
      },
      {
        label: t("files"),
        path: "/files",
        icon: (isSelected) => (
          <img
            src={isSelected ? filesSelectedIcon : filesIcon}
            style={{ width: 24, height: 24 }}
            alt={"files-icon"}
          />
        ),
      },
      !mobileOpen && {
        label: t("messages"),
        path: "/messages",
        badge: unreadMessages,
        icon: (isSelected) => (
          <img
            src={isSelected ? MessagesSelectedIcon : MessagesIcon}
            style={{ width: 24, height: 24 }}
            alt={"message-icon"}
          />
        ),
      },
      {
        label: t("shop"),
        path: "/shop",
        icon: (isSelected) => (
          <img
            src={isSelected ? ShopSelectedIcon : ShopIcon}
            style={{ width: 24, height: 24 }}
            alt={"message-icon"}
          />
        ),
      },
      {
        label: t("forms"),
        path: "/forms",
        icon: (isSelected) => (
          <img
            src={isSelected ? formsSelectedIcon : formsIcon}
            style={{ width: 24, height: 24 }}
            alt={"message-icon"}
          />
        ),
      },
    ].filter(Boolean);

  let secondMenuLinks = (t) =>
    [
      isUserClubAdmin && {
        label: t("register_user"),
        drawerOpened: isRegisterUserDrawerOpen,
        icon: (isSelected) => (
          <img
            src={isSelected ? registerUserSelectedIcon : registerUserIcon}
            style={{ width: 24, height: 24 }}
            alt={"register-user-icon"}
          />
        ),
        onClick: () => setIsRegisterUserDrawerOpen(true),
      },
      !mobileOpen && {
        label: t("notifications"),
        path: "/notifications",
        badge: invitations?.length,
        icon: (isSelected) => (
          <img
            src={isSelected ? notificationSelectedIcon : notificationIcon}
            style={{ width: 24, height: 24 }}
            alt={"notifications-icon"}
          />
        ),
      },
    ].filter(Boolean);

  let superAdminMenuLinks = (t) => {
    return [
      { divider: true },
      {
        label: t("clubs_admin"),
        path: "/super-admin/clubs",
        icon: (isSelected) => (
          <img
            src={isSelected ? ClubSelectedIcon : ClubIcon}
            style={{ width: 24, height: 24 }}
            alt={"club-icon"}
          />
        ),
      },
      {
        label: `${t("search_user")}`,
        path: "/super-admin/impersonate",
        icon: (isSelected) => (
          <img
            src={isSelected ? SearchSelectedIcon : SearchIcon}
            style={{ width: 24, height: 24 }}
            alt={"club-icon"}
          />
        ),
      },
      {
        label: `${t("roles_admin")}`,
        path: "/super-admin/roles",
        icon: (isSelected) => (
          <img
            src={isSelected ? rolesSelectedIcon : rolesIcon}
            style={{ width: 24, height: 24 }}
            alt={"role-icon"}
          />
        ),
      },
      {
        label: t("official_leagues"),
        path: "/super-admin/official-leagues",
        icon: (isSelected) => (
          <img
            src={isSelected ? LeagueSelectedIcon : LeagueIcon}
            style={{ width: 24, height: 24 }}
            alt={"club-icon"}
          />
        ),
      },
      { divider: true },
    ];
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    // shutdown Intercom
    if (Intercom) {
      Intercom("shutdown");
    }

    try {
      // Sign out from Firebase
      await signOut();

      // clear lS and SS
      clearStorages();

      // clear cookies
      clearCookies();

      // Perform logout via auth context
      auth.logout();

      // Close any open drawer
      closeDrawerMobile();
    } catch (error) {
      snackBar.setOptions({
        alertMessage: t("error_during_logout"),
        alertSeverity: "error",
      });
      snackBar.setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    if (search.length >= 3) {
      axiosGraphQL({
        query: GET_PROFILES,
        variables: {
          term: search,
          clubId,
          skip: 0,
          limit: 500,
        },
      })
        .then(async (response) => {
          const players = response.data.data.ClubProfiles || [];

          let teamDetails = await getProfilesTeamDetails(
            players,
            axiosGraphQL,
            "AppShell",
            snackBar,
            errorLog
          );
          const playersOptions = [];
          for (const player of players) {
            for (const team of player?.teams) {
              if (teamDetails[team]) {
                playersOptions.push({
                  label: `${player?.firstName} ${player?.lastName} (${teamDetails[team]?.name})`,
                  value: player?._id,
                  teamId: teamDetails[team]?._id,
                });
              } else {
                playersOptions.push({
                  label: `${player?.firstName} ${player?.lastName}`,
                  value: player?._id,
                });
              }
            }
          }
          setPlayers(playersOptions);
        })
        .catch((error) => {
          errorLog(error, "AppShell", "useEffect", "GET_PROFILES");
          snackBar.setOptions({
            alertMessage: error.message,
            alertSeverity: "error",
          });
          snackBar.setSnackbarOpen(true);
        });
    }
  }, [search]);

  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
  };
  const navigate = useNavigate();

  const handleOnClick = (e, value) => {
    if (value?.value) {
      if (value?.teamId) navigate(`/players/${value?.teamId}/${value?.value}`);
      else navigate(`/players/${value?.value}`);
    }
  };

  const handleChangeSelectClub = async (selectedClubId) => {
    setSelectedClub(user.clubs.find((club) => club?._id === selectedClubId));
    setChooseClubModal(true);
  };

  const updateSelectedClub = async () => {
    const clubToBeSelected = user.clubs.find(
      (club) => club?._id === selectedClub?._id
    );
    user.club = clubToBeSelected;

    auth.setAuthState({
      user: {
        ...user,
        club: selectedClub,
      },
      token: getStorage().getItem("token"),
    });

    chatConnect();

    impersonateProc();

    setChooseClubModal(false);
    closeDrawerMobile();
    navigate(`/news`);
  };

  const onCancelSelectClub = () => {
    setSelectedClub(user.club);
    setChooseClubModal(false);
  };

  const resetSearch = () => {
    setSearch("");
    setPlayers([]);
  };

  const closeDrawerMobile = () => {
    if (mobileOpen) {
      setMobileOpen(false);
    }
  };

  const drawer = (
    <div>
      <Stack
        sx={{ marginTop: "32px", marginLeft: "24px" }}
        direction={"row"}
        spacing={1}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          sx={{ display: { xs: "none", lg: "flex" } }}
        >
          <img
            style={{ width: "32px", height: "32px" }}
            src={playerDexLogo}
            alt="PlayerDex logo"
          />
          <img
            style={{
              width: "117px",
              height: "12px",
              display: "inline-block",
              marginLeft: "8px",
            }}
            src={playerDex}
            alt="PlayerDex"
          />
        </Stack>
        {!mobileOpen ? <TranslationToggle /> : null}
      </Stack>
      <Divider sx={{ display: { xs: "none", lg: "block" } }} />
      <Stack
        direction={"row"}
        alignItems={"center"}
        style={styles.searchFieldContainer}
        sx={{ display: { xs: "none", lg: "flex" } }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={players}
          fullWidth
          value={""}
          noOptionsText={""}
          onChange={handleOnClick}
          onInputChange={(_event, _value, reason) => {
            if (reason === "clear") {
              resetSearch();
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={handleSearch}
              InputProps={{
                ...params.InputProps,
                style: styles.searchField,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: Grey[500] }} />
                  </InputAdornment>
                ),
              }}
              placeholder={t("search")}
              fullWidth
            />
          )}
        />
      </Stack>
      {user?.clubs?.length > 1 ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          style={styles.searchFieldContainer}
        >
          <TextField
            select
            fullWidth
            name={"selectClub"}
            value={selectedClub?._id || user.club?._id}
            InputProps={{
              style: styles.clubsField,
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    alt={user.club?.name}
                    sx={{ width: 24, height: 24 }}
                    src={user.club?.logo || user.club?.name.substring(0, 1)}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              handleChangeSelectClub(event.target.value);
            }}
          >
            {user.clubs.map((club) => (
              <MenuItem key={club?._id} value={club?._id}>
                {club?.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      ) : (
        <Stack
          direction={"row"}
          alignItems={"center"}
          style={styles.searchFieldContainer}
        >
          <TextField
            fullWidth
            name={"selectClub"}
            defaultValue={selectedClub?.name || user.club?.name}
            InputProps={{
              style: { ...styles.clubsField, cursor: "initial" },
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    alt={user.club?.name}
                    sx={{ width: 24, height: 24 }}
                    src={user.club?.logo || user.club?.name.substring(0, 1)}
                  />
                </InputAdornment>
              ),
            }}
            inputProps={{
              readOnly: true,
              style: { cursor: "initial" },
            }}
            onChange={(event) => {
              handleChangeSelectClub(event.target.value);
            }}
          />
        </Stack>
      )}
      <MenuList
        menu={menuLinks}
        t={t}
        pathname={pathname}
        closeDrawerMobile={closeDrawerMobile}
      />
      {isUserSuperAdmin && (
        <MenuList
          menu={superAdminMenuLinks}
          t={t}
          pathname={pathname}
          closeDrawerMobile={closeDrawerMobile}
        />
      )}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const onLogout = () => {
    setLogoutModal(true);
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={localMap[lang]}
    >
      <Box
        sx={{
          display: "flex",
          paddingBottom: "56px",
          paddingTop: { xs: "90px", lg: 0 },
        }}
      >
        <CssBaseline />
        <TopBar
          handleDrawerToggle={handleDrawerToggle}
          handleLogout={onLogout}
          handleOnClick={handleOnClick}
          handleSearch={handleSearch}
          resetSearch={resetSearch}
          players={players}
          invitations={invitations?.length}
          unreadMessages={unreadMessages}
          user={user}
        />
        <Box
          component="nav"
          sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
          aria-label="navigator"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#0D151A",
                ...(getStorage().getItem("impersonate") && {
                  marginTop: "24px",
                }),
                ...(getStorage().getItem("impersonate") && {
                  height: "calc(100% - 24px)",
                }),
              },
            }}
          >
            {drawer}
            <div style={{ height: "100%" }} />
            <MenuList
              menu={secondMenuLinks}
              t={t}
              pathname={pathname}
              closeDrawerMobile={closeDrawerMobile}
            />
            <ClubChooseConfirmationModal
              open={chooseClubModal}
              clubName={selectedClub?.name}
              onClose={onCancelSelectClub}
              onSave={updateSelectedClub}
            />
            <LogoutConfirmationModal
              logout={handleLogout}
              open={logoutModal}
              onClose={() => setLogoutModal(false)}
            />
            <div style={{ padding: "0 16px" }}>
              <List style={{ borderTop: "1px solid #1F2831" }}>
                <ListItem key={"logout"} disablePadding onClick={onLogout}>
                  <ListItemButton>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      style={{ width: "100%" }}
                      justifyContent={"space-between"}
                      spacing={1}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <ListItemIcon style={{ minWidth: "38px" }}>
                          {user?.photo ? (
                            <Avatar src={user?.photo} alt="user photo" />
                          ) : (
                            <Avatar src={user?.photo} alt="user photo">
                              {`${user?.firstName.substring(
                                0,
                                1
                              )}${user?.lastName.substring(0, 1)}`}
                            </Avatar>
                          )}
                        </ListItemIcon>
                        <Stack direction={"column"} spacing={0}>
                          <span style={styles.userName}>
                            {user?.firstName} {user?.lastName}
                          </span>
                          <span style={styles.userTitle}>
                            {t("club_manager")}
                          </span>
                        </Stack>
                      </Stack>
                      <Tooltip title={t("logout")}>
                        <div>
                          <LogoutIcon />
                        </div>
                      </Tooltip>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", lg: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: Base.Black,
              },
            }}
            open
          >
            {drawer}
            <div style={{ height: "100%" }} />
            <MenuList menu={secondMenuLinks} t={t} pathname={pathname} />
            <ClubChooseConfirmationModal
              open={chooseClubModal}
              clubName={selectedClub?.name}
              onClose={onCancelSelectClub}
              onSave={updateSelectedClub}
            />
            <LogoutConfirmationModal
              logout={handleLogout}
              open={logoutModal}
              onClose={() => setLogoutModal(false)}
            />
            <div style={{ padding: "0 16px" }}>
              <List style={{ borderTop: "1px solid #1F2831" }}>
                <ListItem key={"logout"} disablePadding onClick={onLogout}>
                  <ListItemButton>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      style={{ width: "100%" }}
                      justifyContent={"space-between"}
                      spacing={1}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <ListItemIcon style={{ minWidth: "38px" }}>
                          {user?.photo ? (
                            <Avatar src={user?.photo} alt="user photo" />
                          ) : (
                            <Avatar src={user?.photo} alt="user photo">
                              {`${user?.firstName.substring(
                                0,
                                1
                              )}${user?.lastName.substring(0, 1)}`}
                            </Avatar>
                          )}
                        </ListItemIcon>
                        <Stack direction={"column"} spacing={0}>
                          <span style={styles.userName}>
                            {user?.firstName} {user?.lastName}
                          </span>
                          <span style={styles.userTitle}>
                            {t("club_manager")}
                          </span>
                        </Stack>
                      </Stack>
                      <Tooltip title={t("logout")}>
                        <div>
                          <LogoutIcon />
                        </div>
                      </Tooltip>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </Drawer>
        </Box>

        {/* <Toolbar /> */}
        <Suspense fallback={<Loader />}>{children}</Suspense>
        {/* Conditionally render RegisterUserDrawer */}
        <RegisterUserDrawer
          openDrawer={isRegisterUserDrawerOpen}
          onCloseDrawer={() => setIsRegisterUserDrawerOpen(false)}
        />
      </Box>
    </LocalizationProvider>
  );
};

const styles = {
  link: {
    color: Grey[500],
    ...md.medium,
    textDecoration: "none",
  },
  selectedLink: {
    color: Base.PlayerDex,
    ...md.medium,
    textDecoration: "none",
  },
  searchFieldContainer: {
    padding: "0 24px",
    width: "100%",
  },
  searchFieldIcon: {
    borderBottomLeftRadius: "8px",
    borderTopLeftRadius: "8px",
    backgroundColor: Grey[800],
    height: "40px",
  },
  searchField: {
    backgroundColor: Grey[800],
    color: Grey[500],
    borderRadius: "8px",
    marginTop: "24px",
    marginBottom: "24px",
    border: "none",
    height: "40px",
    paddingTop: "4px",
    ...md.normal,
  },
  clubsField: {
    backgroundColor: Grey[800],
    color: Grey[500],
    borderRadius: "8px",
    marginTop: 0,
    marginBottom: "24px",
    border: "none",
    height: "40px",
    paddingTop: "4px",
    ...md.normal,
  },
  userName: {
    ...sm.medium,
    color: Base.White,
  },
  userTitle: {
    ...sm.normal,
    color: Grey[500],
  },
};

export default AppShell;
