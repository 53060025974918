export const RSVP = `
  fragment rsvpFields on RSVP {
    _id
    user
    profile
    teamIds
    clubId
    eventGroupId
    eventId
    isGoing
    message
    createdAt
    updatedAt
  }
`;

export const MATCH_REPORT = `
  fragment matchReportFields on MatchReport {
    _id
    eventId
    eventType
    homeScore
    awayScore
    formation
    createdBy
  }
`;

export const EVENT_FIELDS = `
  ${RSVP}
  ${MATCH_REPORT}
  fragment eventFields on EventWithRSVP {
    _id
    club
    teams
    team
    opponentTeam
    opponentClub
    leagueFixture
    fromDate
    fromTime
    toDate
    toTime
    profiles
    homeMatch
    arrivalDate
    arrivalTime
    rsvpDeadlineDate
    rsvpDeadlineTime
    availableRsvps
    notes
    timezone
    location {
      address
      coordinates
      type
    }
    eventType
    title
    isCancelled
    isUpdated
    isPrivate
    isFC
    createdBy
    groupId
    rsvps {
      ...rsvpFields
    }
    matchReport {
      ...matchReportFields
    }
    metadata {
      trainingType
      scheduleType
    }
  }
`;

export const CREATE_TRAINING_EVENT = `
  mutation CreateTraining(
  $team: String!, 
  $schedule: String!, 
  $trainingType: String!, 
  $fromDate: String!,
  $fromTime: String!,
  $toDate: String!, 
  $toTime: String!, 
  $arrivalDate: String,
  $arrivalTime: String,
  $rsvpDeadlineDate: String,
  $rsvpDeadlineTime: String,
  $availableRsvps: Int,
  $notes: String,
  $invitedPlayers: [String], 
  $club: String, 
  $timezone: String, 
  $location: LocationInput, 
  $title: String,
  $preferredEndDate: String,
  $interval: Int) {
    createTraining(
    team: $team,
    schedule: $schedule,
    trainingType: $trainingType,
    fromDate: $fromDate,
    fromTime: $fromTime,
    toDate: $toDate,
    toTime: $toTime,
    arrivalDate: $arrivalDate,
    arrivalTime: $arrivalTime,
    rsvpDeadlineDate: $rsvpDeadlineDate,
    rsvpDeadlineTime: $rsvpDeadlineTime,
    availableRsvps: $availableRsvps,
    notes: $notes,
    invitedPlayers: $invitedPlayers,
    club: $club,
    timezone: $timezone,
    location: $location, 
    title: $title,
	preferredEndDate: $preferredEndDate,
	interval: $interval) {
        eventId
        groupId
    }
  }
`;

export const CREATE_MATCH_EVENT = `
  mutation CreateMatch(
  $opponentClub: String,
  $team: String!, 
  $opponentTeam: String, 
  $homeMatch: Boolean!,
  $fromDate: String!,
  $fromTime: String!,
  $toDate: String!,
  $toTime: String!,
  $arrivalDate: String,
  $arrivalTime: String,
  $rsvpDeadlineDate: String,
  $rsvpDeadlineTime: String,
  $availableRsvps: Int,
  $notes: String,
  $invitedPlayers: [String], 
  $club: String,
  $leagueFixture: String,
  $title: String,
  $timezone: String,
  $location: LocationInput) {
    createMatch(
    opponentClub: $opponentClub,
    team: $team,
    opponentTeam: $opponentTeam,
    homeMatch: $homeMatch,
    fromDate: $fromDate,
    fromTime: $fromTime,
    toDate: $toDate,
    toTime: $toTime,
    arrivalDate: $arrivalDate,
    arrivalTime: $arrivalTime,
    rsvpDeadlineDate: $rsvpDeadlineDate,
    rsvpDeadlineTime: $rsvpDeadlineTime,
    availableRsvps: $availableRsvps,
    notes: $notes,
    invitedPlayers: $invitedPlayers,
    club: $club,
    timezone: $timezone,
    leagueFixture: $leagueFixture,
    location: $location,
    title: $title) 
  }
`;

export const CREATE_EVENT_EVENT = `
  mutation CreateEvent(
  $club: String,
  $trainingType: String, 
  $fromDate: String!,
  $fromTime: String!,
  $toDate: String!, 
  $toTime: String!, 
  $title: String,
  $text: String, 
  $arrivalDate: String,
  $arrivalTime: String,
  $rsvpDeadlineDate: String,
  $rsvpDeadlineTime: String,
  $availableRsvps: Int,
  $notes: String,
  $location: LocationInput!, 
  $teams: [String]!,
  $timezone: String,
  $invitedPlayers: [String]) {
  createEvent(
  club:  $club,
  trainingType: $trainingType, 
  fromDate: $fromDate,
  fromTime: $fromTime,
  toDate: $toDate, 
  toTime: $toTime, 
  title: $title,
  text: $text,
  arrivalDate: $arrivalDate,
  arrivalTime: $arrivalTime,
  rsvpDeadlineDate: $rsvpDeadlineDate,
  rsvpDeadlineTime: $rsvpDeadlineTime,
  availableRsvps: $availableRsvps,
  notes: $notes,
  location: $location, 
  teams: $teams,
  timezone: $timezone,
  invitedPlayers: $invitedPlayers) {
   _id
  }
  
  }
`;

export const UPDATE_EVENT = `
  mutation UpdateActivity(
  $groupId: String!, 
  $eventId: String!, 
  $trainingType: String, 
  $fromDate: String,
  $fromTime: String,
  $toDate: String, 
  $toTime: String, 
  $title: String,
  $arrivalDate: String,
  $arrivalTime: String,
  $rsvpDeadlineDate: String,
  $rsvpDeadlineTime: String,
  $availableRsvps: Int,
  $notes: String,
  $location: LocationInput,
  $homeMatch: Boolean,
  $updateNextEvents: Boolean!) {
    updateEvent(groupId: $groupId,
    eventId: $eventId,
    trainingType: $trainingType,
    fromDate: $fromDate,
    fromTime: $fromTime,
    toDate: $toDate,
    toTime: $toTime,
    title: $title,
    arrivalDate: $arrivalDate,
    arrivalTime: $arrivalTime,
    rsvpDeadlineDate: $rsvpDeadlineDate,
    rsvpDeadlineTime: $rsvpDeadlineTime,
    availableRsvps: $availableRsvps,
    notes: $notes,
    location: $location,
    homeMatch: $homeMatch,
    updateNextEvents: $updateNextEvents) {
      _id
	  groupId
    }
  }
`;

export const CANCEL_EVENT = `
  mutation cancelActivity(
    $groupId: String!, 
    $eventId: String!, 
    $text: String) {
      cancelEvent(
        groupId: $groupId,
        eventId: $eventId,
        text: $text
      )
  }
`;

export const DELETE_EVENT = `
  mutation deleteActivity(
    $groupId: String!, 
    $eventId: String!, 
    $deleteNextEvents: Boolean!) {
      deleteEvent(
        groupId: $groupId,
        eventId: $eventId,
        deleteNextEvents: $deleteNextEvents
      )
  }
`;

export const ADD_REMOVE_RSVP = `
   mutation (
    $eventId: String!,
    $eventGroupId: String,
    $profileId: String,
    $userId: String,
    $removeRSVP: Boolean!,
    $club: String) {
      addRemoveRSVP(
        eventId: $eventId,
        eventGroupId: $eventGroupId,
        profileId: $profileId,
        userId: $userId,
        removeRSVP: $removeRSVP,
        club: $club
      )
  }
`;

export const SEND_REMINDER = `mutation sendReminder($eventId: String!, $club: String, $teams: [String], $profiles: [String]) {
    sendReminder(eventId: $eventId, club: $club, teams: $teams, profiles: $profiles)
  }
`;

export const GET_EVENTS = `
  ${EVENT_FIELDS}
  query GetEvents($teamId: String!, $clubId: String, $teams: [String], $eventType: String, $fromDate: String, $fromTime: String, $toDate: String, $toTime: String, $profiles: [String], $skip: Int = 0, $limit: Int = 20, $sortingDir: String, $sortingField: String) {
    events(teamId: $teamId, clubId: $clubId, teams: $teams, eventType: $eventType, fromDate: $fromDate, fromTime: $fromTime, toDate: $toDate, toTime: $toTime, profiles: $profiles, skip: $skip, limit: $limit, sortingDir: $sortingDir, sortingField: $sortingField) {
      ...eventFields
    }
  }
`;

export const GET_EVENT = `
  ${EVENT_FIELDS}
  query GetEvent($id: String, $groupId: String) {
    event(id: $id, groupId: $groupId) {
      ...eventFields
    }
  }
`;

const RSVP_FIELDS = `
fragment rsvpFields on RSVP {
    _id
    user
    profile
    eventGroupId
    eventId
    isGoing
    message
    createdAt
    updatedAt
}
`;

export const REPLY_TO_RSVP = `
  ${RSVP_FIELDS}
  mutation ReplyToRSVP($id: String!, $going: Boolean!, $message: String, $resetIsGoing: Boolean, $resetMessage: Boolean) {
    replyToRsvp(id: $id, going: $going, message: $message, resetIsGoing: $resetIsGoing, resetMessage: $resetMessage) {
      ...rsvpFields
    }
  }
`;
