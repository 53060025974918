import firebase from "firebase/compat/app";

const isDevelopment = process.env.REACT_APP_NODE_ENV === "development";
const firebaseConfig = isDevelopment
  ? {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: "playerdexdev.firebaseapp.com",
      projectId: "playerdexdev",
      storageBucket: "playerdexdev.firebasestorage.app",
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    }
  : {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: "playerdex-app.firebaseapp.com",
      projectId: "playerdex-app",
      storageBucket: "playerdex-app.appspot.com",
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

firebase.initializeApp(firebaseConfig);

export default firebase;
