import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Badge from "@mui/material/Badge";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Search from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import TranslationToggle from "../../Components/TranslationToggle";
import playerDexLogo from "../../assets/images/admin-icon-2/Content.png";
import playerDex from "../../assets/images/admin-icon-2/playerdex.png";
import GbFlag from "../../assets/images/flags/GB.svg";
import GrFlag from "../../assets/images/flags/GR.svg";
import notificationIcon from "../../assets/images/icons/notificationIcon.svg";
import MessagesIcon from "../../assets/images/icons/messages.png";
import { useTranslation } from "../../context/Internationalization";
import { colors } from "../../theme/colors";
import { fonts } from "../../theme/fonts";
import { getStorage } from "../../auth/utils";

const { Grey, Base } = colors;
const {
  text: { md },
} = fonts;

const styles = {
  logoContainer: {
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  searchFieldContainer: {
    padding: "0 24px",
    width: "100%",
  },
  searchFieldIcon: {
    borderBottomLeftRadius: "8px",
    borderTopLeftRadius: "8px",
    backgroundColor: Grey[800],
    height: "40px",
  },
  searchField: {
    backgroundColor: Grey[800],
    color: Grey[500],
    borderRadius: "8px",
    marginTop: "24px",
    marginBottom: "24px",
    border: "none",
    height: "40px",
    paddingTop: "4px",
    maxWidth: "264px",
    ...md.normal,
  },
  menuLinks: {
    color: Grey[500],
    backgroundColor: "transparent",
    ...md.normal,
  },
  logoutButton: {
    color: Grey[500],
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color: Grey[700],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      backgroundColor: Base.Black,
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        backgroundColor: Grey[700],
      },
    },
  },
}));

const TopBar = ({
  handleDrawerToggle,
  handleLogout,
  handleOnClick,
  handleSearch,
  resetSearch,
  players,
  invitations,
  unreadMessages,
  user,
}) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const isUserMenuOpen = Boolean(anchorElUser);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();
  const { t, switchLanguage, lang } = useTranslation();
  const impersonate = getStorage().getItem("impersonate");

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleUserMenuOpen = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorElUser(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLanguageSwitch = () => {
    switchLanguage(lang === "gr" ? "en" : "gr");
  };

  return (
    <AppBar
      component="nav"
      sx={{
        display: { xs: "flex", lg: "none" },
        backgroundColor: Base.Black,
        marginTop: impersonate ? `${Math.max(24 - scrollPosition, 0)}px` : 0,
      }}
    >
      <Toolbar>
        <Tooltip title={t("menu")}>
          <IconButton
            color="primary"
            aria-label="open menu drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, color: Grey["500"] }}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <Typography
          variant="h6"
          component="div"
          style={styles.logoContainer}
          sx={{ display: { xs: "none", md: "flex" } }}
          onClick={() => navigate("/")}
        >
          <img
            style={{ width: "32px", height: "32px" }}
            src={playerDexLogo}
            alt="PlayerDex logo"
          />
          <img
            style={{
              width: "117px",
              height: "12px",
              display: "inline-block",
              marginLeft: "8px",
            }}
            src={playerDex}
            alt="PlayerDex"
          />
        </Typography>
        <Typography
          variant="h6"
          component="div"
          style={styles.logoContainer}
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <img
            style={{ width: "32px", height: "32px" }}
            src={playerDexLogo}
            alt="PlayerDex logo"
          />
        </Typography>
        <Stack
          direction={"row"}
          alignItems={"center"}
          flexGrow={1}
          style={styles.searchFieldContainer}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={players}
            fullWidth
            value={""}
            noOptionsText={""}
            onChange={handleOnClick}
            onInputChange={(_event, _value, reason) => {
              if (reason === "clear") {
                resetSearch();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={handleSearch}
                InputProps={{
                  ...params.InputProps,
                  style: styles.searchField,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ color: Grey[500] }} />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("search")}
                fullWidth
              />
            )}
          />
        </Stack>
        <Box sx={{ flexGrow: 0, display: { xs: "none", sm: "flex" } }}>
          <Stack direction={"row"} alignItems={"center"}>
            <Tooltip title={t("messages")}>
              <IconButton
                color="primary"
                aria-label="messages"
                sx={{ mr: 2, color: Grey["500"] }}
                onClick={() => navigate(`/messages`)}
              >
                {unreadMessages && unreadMessages > 0 ? (
                  <Badge badgeContent={unreadMessages} color="error">
                    <img
                      src={MessagesIcon}
                      style={{ width: 24, height: 24 }}
                      alt={"message-icon"}
                    />
                  </Badge>
                ) : (
                  <img
                    src={MessagesIcon}
                    style={{ width: 24, height: 24 }}
                    alt={"message-icon"}
                  />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title={t("notifications")}>
              <IconButton
                color="primary"
                aria-label="notifications"
                sx={{ mr: 2, color: Grey["500"] }}
                onClick={() => navigate(`/notifications`)}
              >
                {invitations ? (
                  <Badge badgeContent={invitations} color="error">
                    <img
                      src={notificationIcon}
                      style={{ width: 24, height: 24 }}
                      alt={"notifications-icon"}
                    />
                  </Badge>
                ) : (
                  <img
                    src={notificationIcon}
                    style={{ width: 24, height: 24 }}
                    alt={"notifications-icon"}
                  />
                )}
              </IconButton>
            </Tooltip>
            <TranslationToggle />
            <Tooltip title={t("user")}>
              <IconButton
                color="primary"
                aria-label="open user menu"
                onClick={handleUserMenuOpen}
                sx={{ padding: 0 }}
              >
                <Badge
                  overlap="circular"
                  badgeContent={
                    <ExpandMoreIcon sx={{ color: Grey[500], fontSize: 14 }} />
                  }
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: `${Grey[700]}`,
                      padding: 0,
                      minWidth: 16,
                      width: 16,
                      height: 16,
                    },
                  }}
                >
                  {user?.photo ? (
                    <Avatar
                      alt="user photo"
                      src={user?.photo}
                      sx={{ width: 30, height: 30 }}
                    />
                  ) : (
                    <Avatar
                      alt="user photo"
                      sx={{ width: 30, height: 30, fontSize: 14 }}
                    >
                      {`${user?.firstName?.substring(
                        0,
                        1
                      )}${user?.lastName?.substring(0, 1)}`}
                    </Avatar>
                  )}
                </Badge>
              </IconButton>
            </Tooltip>
            <StyledMenu
              id="user-menu"
              MenuListProps={{
                "aria-labelledby": "user-menu-list",
              }}
              anchorEl={anchorElUser}
              open={isUserMenuOpen}
              onClose={handleMenuClose}
            >
              <MenuItem key={"0"} onClick={handleMenuClose}>
                <Typography style={styles.menuLinks} onClick={handleLogout}>
                  <IconButton
                    color="primary"
                    edge="end"
                    aria-label="Logout"
                    style={styles.logoutButton}
                  >
                    <LogoutIcon />
                  </IconButton>
                  {t("logout")}
                </Typography>
              </MenuItem>
            </StyledMenu>
          </Stack>
        </Box>
        <Box sx={{ display: { xs: "flex", sm: "none" } }}>
          <Tooltip title={t("show_more")}>
            <IconButton
              color="primary"
              size="large"
              aria-label="Show more"
              aria-controls={"menu-mobile"}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              sx={{ color: Grey["500"] }}
            >
              <MoreIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <StyledMenu
          id="user-menu"
          MenuListProps={{
            "aria-labelledby": "user-menu-list",
          }}
          anchorEl={mobileMoreAnchorEl}
          open={isMobileMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem key={"0"} onClick={handleMobileMenuClose}>
            <Typography
              style={styles.menuLinks}
              onClick={() => navigate(`/messages`)}
            >
              <IconButton
                color="primary"
                aria-label="messages"
                sx={{ mr: 2, color: Grey["500"] }}
              >
                {unreadMessages && unreadMessages > 0 ? (
                  <Badge badgeContent={unreadMessages} color="error">
                    <img
                      src={MessagesIcon}
                      style={{ width: 24, height: 24 }}
                      alt={"message-icon"}
                    />
                  </Badge>
                ) : (
                  <img
                    src={MessagesIcon}
                    style={{ width: 24, height: 24 }}
                    alt={"message-icon"}
                  />
                )}
              </IconButton>
              {t("messages")}
            </Typography>
          </MenuItem>
          <MenuItem key={"1"} onClick={handleMobileMenuClose}>
            <Typography
              style={styles.menuLinks}
              onClick={() => navigate(`/notifications`)}
            >
              <IconButton
                color="primary"
                aria-label="notifications"
                sx={{ mr: 2, color: Grey["500"] }}
              >
                {invitations ? (
                  <Badge badgeContent={invitations} color="error">
                    <img
                      src={notificationIcon}
                      style={{ width: 24, height: 24 }}
                      alt={"notifications-icon"}
                    />
                  </Badge>
                ) : (
                  <img
                    src={notificationIcon}
                    style={{ width: 24, height: 24 }}
                    alt={"notifications-icon"}
                  />
                )}
              </IconButton>
              {t("notifications")}
            </Typography>
          </MenuItem>
          <MenuItem key={"2"} onClick={handleMobileMenuClose}>
            <Typography style={styles.menuLinks} onClick={handleLanguageSwitch}>
              <IconButton
                color="primary"
                aria-label="toggle language"
                sx={{ mr: 2, color: Grey["500"] }}
              >
                <img
                  src={lang === "gr" ? GrFlag : GbFlag}
                  style={{ width: 24, height: 24 }}
                  alt={"language-icon"}
                />
              </IconButton>
              {lang === "gr" ? t("switch_to_english") : t("switch_to_greek")}
            </Typography>
          </MenuItem>
          <MenuItem key={"3"} onClick={handleMobileMenuClose}>
            <Typography style={styles.menuLinks} onClick={handleLogout}>
              <IconButton
                color="primary"
                edge="end"
                aria-label="Logout"
                style={styles.logoutButton}
                sx={{ mr: 1 }}
              >
                <LogoutIcon />
              </IconButton>
              {t("logout")}
            </Typography>
          </MenuItem>
        </StyledMenu>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {};

export default TopBar;
